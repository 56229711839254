import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.svarka.kg/api/category/';

class CategoryService {

    get(id) {
        return axios.get(API_URL + 'get',{'params':{'id':id}});
    }

    getAll() {
        return axios.get(API_URL + 'getAll');
    }
    getAllActiveCategories() {
        return axios.get(API_URL + 'getAllActiveCategories');
    }
    
    create(title, seoFriendlyUrl, status) {
        console.log('seoFriendlyUrl: ',seoFriendlyUrl)
        return axios.post(API_URL + "create", 
        {  title, seoFriendlyUrl, status});
    }

    update(_id, title, seoFriendlyUrl, status) {
        // console.log('price: ',price)
        return axios.put(API_URL + "update", 
        {  _id, title, seoFriendlyUrl, status});
    }

    delete(id) {
        console.log(id)
        return axios.delete(API_URL + 'delete?id=' + id);
    }

    getAllProductsByCategoryId(categoryId){
        return axios.get(API_URL + 'getAllProductsByCategoryId',{'params':{'categoryId':categoryId}})
    }
}

export default CategoryService = new CategoryService();