import React, { Component } from "react";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import logo from './logo.png';
import AuthService from "./services/auth.service";

import Login from "./components/others/login.component";
import Register from "./components/others/register.component";
import Home from "./components/others/home.component";
// import One from "./components/one.component";
import Profile from "./components/others/profile.component";
import BoardUser from "./components/others/board-user.component";
import BoardModerator from "./components/others/board-moderator.component";
import BoardAdmin from "./components/others/board-admin.component";

import ListUser from "./components/users/list-user.component";
import CreateUser from "./components/users/create-user.component";
import UpdateUser from "./components/users/update-user.component";
import ViewUser from "./components/users/view-user.component";

import ListCategory from "./components/categories/list-category.component";
import CreateCategory from "./components/categories/create-category.component";
import UpdateCategory from "./components/categories/update-category.component";
import ViewCategory from "./components/categories/view-category.component";

import ListProduct from "./components/products/list-product.component";
import CreateProduct from "./components/products/create-product.component";
import UpdateProduct from "./components/products/update-product.component";
import ViewProduct from "./components/products/view-product.component";
import CreateMultiple from "./components/products/create-multiple.component";

import ListProductAdmin from "./components/products/list-product-admin.component";
import ViewProductAdmin from "./components/products/view-product-admin.component";

import ListXlsx from "./components/xlsx/list-xlsx.component";

import EventBus from "./common/EventBus";
import Collapsible from "./Collapsible";
import CollapsibleAdminMenu from "./CollapsibleAdminMenu";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }
  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard  } = this.state;

    return (
      <div>
        {currentUser ? (
        <CollapsibleAdminMenu/>
         ) : ''} 
        
        <header className="p-3 text-bg-light">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none logo-text"> 
                <span className="logo-text-1">SVARKA</span>
                <span className="logo-text-2">.KG</span>
              </a>
              <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></ul>
              <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search"></form>
              <div className="text-end">              
                <Link className="underline-removed" to={"https://wa.me/+996555255849?text=svarka.kg"} >
                  <i className="bi font-size bi-whatsapp whatsapp-color"></i>&nbsp;
                  +996555255849
                </Link>
                <br></br>
                <Link className="underline-removed instagram-text" to={"https://www.instagram.com/svarka.kg"} >
                  <i className="bi font-size bi-instagram instagram-color"></i>&nbsp;
                  @svarka.kg
                </Link>
                <br></br>
                <Link className="underline-removed facebook-text" to={"https://www.facebook.com/svarka.kg"} >
                  <i className="bi font-size bi-facebook facebook-color"></i>&nbsp;
                  svarka.kg
                </Link>
              </div>
            </div>
          </div>
        </header>

        <Collapsible/>
        

        <div className="container mt-3 content">
          <Routes>
            <Route path="/" element={<Home />} />

            {/* <Route path="/one" element={<One />} /> */}
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />

            <Route path="/list-user" element={<ListUser />} />
            <Route path="/create-user" element={<CreateUser />} />    
            <Route path="/update-user/:id" element={<UpdateUser />} />          
            <Route path="/view-user/:id" element={<ViewUser />} />     

            <Route path="/list-category" element={<ListCategory />} />
            <Route path="/create-category" element={<CreateCategory />} />    
            <Route path="/update-category/:id" element={<UpdateCategory />} />          
            <Route path="/view-category/:id" element={<ViewCategory />} />     

            <Route path="/list-product/:categoryId" element={<ListProduct />} />
            <Route path="/create-product/:categoryId" element={<CreateProduct />} />    
            <Route path="/update-product/:id" element={<UpdateProduct />} />          
            <Route path="/view-product/:id" element={<ViewProduct />} />              
            <Route path="/create-multiple" element={<CreateMultiple />} />    

            <Route path="/list-product-admin/:categoryId" element={<ListProductAdmin />} />
            <Route path="/view-product-admin/:id" element={<ViewProductAdmin />} />    
            <Route path="/list-xlsx" element={<ListXlsx />} />
          </Routes>
        </div>

        {/* <AuthVerify logOut={this.logOut}/> */}



        <footer className="page-footer font-small blue pt-4 bg-dark">

          <div className="container-fluid text-center text-md-left">        

          </div>
          <div className="footer-copyright text-center py-3">© 2024 Copyright:
            <a href="https://svarka.kg"> svarka.kg</a>
          </div>

        </footer>
      </div>
    );
  }
}

export default App;
