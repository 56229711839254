import axios from 'axios';
// import authHeader from './auth-header';

// const API_URL = 'https://liftmarket-api.secom.kg/api/test/';
const API_USER_URL = 'https://api.svarka.kg/api/user/';
// const API_URL = 'http://localhost:4004/api/test/';
// const API_USER_URL = 'http://localhost:4004/api/user/';

class UserService {

  create(username, email, password, roles, branchId) {
    console.log('roles: ',roles)
    return axios.post(API_USER_URL + "create", {
      username,
      email,
      password,
      roles,
      branchId
    });
  }

  get(id) {
    return axios.get(API_USER_URL + 'get',{'params':{'id':id}});
  }

  update(_id, username, email, password, roles, branchId) {
    console.log('roles: ',roles)
    return axios.put(API_USER_URL + "update", {
      _id,
      username,
      email,
      password,
      roles,
      branchId
    });
  }

  delete(id) {
    console.log(id)
    return axios.delete(API_USER_URL + 'delete?id=' + id);
  }

  getAll() {
    return axios.get(API_USER_URL + 'getAll');
  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  // getUserBoard() {
  //   return axios.get(API_URL + 'user', { headers: authHeader() });
  // }

  // getModeratorBoard() {
  //   return axios.get(API_URL + 'mod', { headers: authHeader() });
  // }

  // getAdminBoard() {
  //   return axios.get(API_URL + 'admin', { headers: authHeader() });
  // }
}

export default UserService = new UserService();